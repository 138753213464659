.my-button {
    display: flex;
    align-items: center;
  }
  
  .right {
    order: 1;
  }
  .react-flow__node-default, .react-flow__node-input, .react-flow__node-output {
    color: #222;
    font-size: 12px;
    text-align: unset;
}