.container{max-width:1170px; margin:auto;}
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 20%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 100%; 
}
.headind_srch{ padding:10px 29px 10px 0px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:100%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -20px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{cursor: pointer;
  overflow:hidden; clear:both; margin-left: 5px;margin-right: 5px;margin-top: 5px; padding: 10px;border-bottom: 1px solid #eeb141; margin-bottom: 10px;
  
}
.chat_people:hover{background:#beaf24b4;}
.activechat{ 
  background:#beaf24b4;
}

.chat_list {
  //border-bottom: 1px solid #eeb141;
  margin: 0;
  //padding: 10px;
 // padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 100%;}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 40%;
  background-color: white;
}
.data-pasien-konsul{
  float: left;
  padding: 30px 15px 0 25px;
  width: 40%;
  border-left: 1px solid #c4c4c4;
}
.panel-bantuan{
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
  border-left: 1px solid #c4c4c4;
}
.data-produk-konsul{
  float: left;
  padding: 30px 15px 0 25px;
  height: 516px;
  overflow-y: auto;
  width: 100%;
}
.data-dokter-konsul{
  float: left;
  padding: 30px 15px 0 25px;
  height: 416px;
  overflow-y: auto;
  width: 100%;
}
.mesgs2 {
  float: left;
  padding: 30px 15px 0 25px;
  width: 100%;
}


 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}
.msg_history {
  height: 516px;
  overflow-y: auto;
}



/* cs caht*/
.cschat{
  
.card {
  width: 300px;
  border: none;
  border-radius: 15px;
}
.body-cs-chat{

  height: 316px;
  overflow-y: auto;
}

.adiv {
  background: #c2b179;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
  height: 46px
}

.chat {
  border: none;
  background: #E2FFE8;
  font-size: 10px;
  border-radius: 20px
}

.bg-white {
  border: 1px solid #E7E7E9;
  font-size: 10px;
  border-radius: 20px
}

.myvideo img {
  border-radius: 20px
}

.dot {
  font-weight: bold
}

.form-control {
  border-radius: 12px;
  border: 1px solid #F0F0F0;
  font-size: 8px
}

.form-control:focus {
  box-shadow: none
}

.form-control::placeholder {
  font-size: 8px;
  color: #C4C4C4
}

}